import React, {useEffect, useState} from "react";
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import pivotLogo from '../../../images/locks/product-pages/pivot/logo-gray-pivot.png';
import digilinkLogo from '../../../images/logos/logo-digilink.svg';
import ReactVideo from "../../../components/video-player/ReactVideo";
import Modal from '../../../components/modals/modal';
import posterDigilink from "../../../images/support/posters/poster-homepage-digilink.jpeg";
import logoCurve from '../../../images/locks/product-pages/curve/logo-gray-curve.png';
import logoAspire from '../../../images/locks/product-pages/aspire/logo-gray-aspire.png';
import logoVersa from '../../../images/locks/product-pages/versa/logo-gray-versa.png';
import logoOrbit from '../../../images/locks/product-pages/orbit/logo-gray-orbit.png';
import CustomLocalizedLink from "../../../components/locales/custom-localized-link";
import sensibaLogo from '../../../images/logos/Sensiba-ISO-IEC-27001_Digital.png';


const IndexPage = () => {

  const {t} = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showModalSOC2, setShowModalSOC2] = useState(false);

  let handleShowModal = e => {
    const bodyElm = document.body;
    setShowModal(prevShowModal => !prevShowModal);
    bodyElm.classList.toggle('stop-body-scroll');
  }

  let handleShowModalKeyDown = e => {
    const bodyElm = document.body;
    if (e.keyCode === 13) {
      setShowModal(prevShowModal => !prevShowModal);
      bodyElm.classList.toggle('stop-body-scroll');
    }
  }

  let handleShowModalSOC2 = e => {
    const bodyElm = document.body;
    setShowModalSOC2(prevshowModalSOC2 => !prevshowModalSOC2);
    bodyElm.classList.toggle('stop-body-scroll');
  }

  function handleScrollTo(elmID) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: document.getElementById(elmID).offsetTop - 150
    });
  }

  useEffect(() => {
    if (document.location.hash === '#gotoCurve') {
      handleScrollTo("smartCurve");
    }
    if (document.location.hash === '#gotoAspire') {
      handleScrollTo("smartAspire");
    }
    if (document.location.hash === '#gotoVersa') {
      handleScrollTo("smartVersa");
    }
    if (document.location.hash === '#gotoOrbit') {
      handleScrollTo("smartOrbit");
    }
    if (document.location.hash === '#gotoDigilink') {
      handleScrollTo("smartDigilink");
    }
    if (document.location.hash === '#gotoPivot') {
      handleScrollTo("smartPivot");
    }
  }, []);

  return (
      <Layout>
        <Seo
            title={t('products_smart_locks')}
            description={t('des_products_smart_locks')}
        />
        <div className="products smart-locks-page">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/smart-locks/hero-smart.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Automated Doors with Digilock Curve, Aspire, Versa or Orbit Locks"
            />
            <h1>
              <Trans>
                smart_locks
              </Trans>
            </h1>
          </section>
          <section className={'smart-locks-overview'}>
            <div className="container">
              <h2 className={'headline'} dangerouslySetInnerHTML={{__html: t('sl_headline')}} />
              <div className="center">
                <ul>
                  <li><Trans>sl_headline_list_1</Trans></li>
                  <li><Trans>sl_headline_list_2</Trans></li>
                  {/*<li><Trans>sl_headline_list_2_5</Trans></li>*/}
                  <li><Trans>sl_headline_list_3</Trans></li>
                  <li>
                    <span
                        onKeyDown={handleShowModalKeyDown}
                        onClick={handleShowModal}>
                      <Trans>sl_headline_list_5</Trans>
                    </span>
                  </li>
                  <li><Trans>wirefree_locks</Trans></li>
                  <li><Trans>pivot_hardwired</Trans></li>
                  <li><Trans>sl_headline_list_7</Trans></li>
                  <li><Trans>sl_headline_list_8</Trans></li>
                </ul>
              </div>
            </div>
          </section>
          <div id={'smartCurve'} className="section-delimeter container" />
          <section className="smart-curve">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <img className={'logo'} src={logoCurve} alt="Curve Logo" width={'183'} height={'52'}/>
                  <h2>
                    <Trans>sl_curve_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>sl_curve_list1</Trans></li>
                    <li><Trans>wireless_connectivity_global</Trans></li>
                    <li><Trans>sl_curve_list2</Trans></li>
                    <li><Trans>sl_curve_list3</Trans></li>
                    <li><Trans>sl_curve_list4</Trans></li>
                    <li><Trans>sl_curve_list5</Trans></li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto={'/products/smart-locks/curve/'} label={t('see_specs')} cls={'btn btn--orange'}/>
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/smart-locks/smart-curve-four.png"
                      loading={'lazy'}
                      width={624}
                      height={461}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Curve smart lock"
                  />
                </div>
              </div>
            </div>
          </section>
          <div id={'smartAspire'} className="section-delimeter container" />
          <section className="smart-aspire">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/smart-locks/smart-aspire-four.png"
                      loading={'lazy'}
                      width={624}
                      height={461}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Aspire smart lock"
                  />
                </div>
                <div className="grid-two-col-item-single">
                  <img className={'logo'} src={logoAspire} alt="Aspire Logo" width={'191'} height={'65'}/>
                  <h2>
                    <Trans>sl_aspire_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>sl_aspire_list1</Trans></li>
                    <li><Trans>wireless_connectivity_global</Trans></li>
                    <li><Trans>sl_aspire_list2</Trans></li>
                    <li><Trans>sl_aspire_list3</Trans></li>
                    <li><Trans>sl_aspire_list4</Trans></li>
                    <li><Trans>sl_aspire_list5</Trans></li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto={'/products/smart-locks/aspire/'} label={t('see_specs')} cls={'btn btn--orange'}/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id={'smartVersa'} className="section-delimeter container" />
          <section className="smart-versa">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <img className={'logo'} src={logoVersa} alt="Versa Logo" width={'162'} height={'50'}/>
                  <h2>
                    <Trans>sl_versa_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>sl_versa_list1</Trans></li>
                    <li><Trans>wireless_connectivity_global</Trans></li>
                    <li><Trans>sl_versa_list2</Trans></li>
                    <li><Trans>sl_versa_list3</Trans></li>
                    <li><Trans>sl_versa_list4</Trans></li>
                    <li><Trans>sl_versa_list5</Trans></li>
                    <li><Trans>sl_versa_list6</Trans></li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto={'/products/smart-locks/versa/'} label={t('see_specs')} cls={'btn btn--orange'}/>
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/smart-locks/smart-versa-four.png"
                      loading={'lazy'}
                      width={624}
                      height={461}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Versa smart lock"
                  />
                </div>
              </div>
            </div>
          </section>
          <div id={'smartOrbit'} className="section-delimeter container" />
          <section className="smart-orbit">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/smart-locks/smart-orbit-four.png"
                      loading={'lazy'}
                      width={624}
                      height={461}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Orbit smart lock"
                  />
                </div>
                <div className="grid-two-col-item-single">
                  <img className={'logo'} src={logoOrbit} alt="Orbit Logo" width={'163'} height={'53'}/>
                  <h2>
                    <Trans>sl_orbit_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>sl_orbit_list1</Trans></li>
                    <li><Trans>wireless_connectivity_global</Trans></li>
                    <li><Trans>sl_orbit_list2</Trans></li>
                    <li><Trans>sl_orbit_list3</Trans></li>
                    <li><Trans>sl_orbit_list4</Trans></li>
                    <li><Trans>sl_orbit_list5</Trans></li>
                    <li><Trans>sl_orbit_list6</Trans></li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto={'/products/smart-locks/orbit/'} label={t('see_specs')} cls={'btn btn--orange'}/>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div id={'smartPivot'} className="section-delimeter container" />
          <section className="smart-pivot">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single pivot is-reversable">
                  <img className={'logo'} src={pivotLogo} width={'139'} height={'50'} alt="Pivot logo"/>
                  <h2>
                    <Trans>sl_pivot_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>sl_pivot_headline_list_1</Trans></li>
                    <li><Trans>sl_pivot_headline_shares</Trans></li>
                    <li><Trans>sl_pivot_headline_list_2</Trans></li>
                    <li><Trans>sl_pivot_headline_list_3</Trans></li>
                    <li><Trans>sl_pivot_headline_list_4</Trans></li>
                    <li><Trans>sl_pivot_headline_list_5</Trans></li>
                    <li><Trans>sl_pivot_headline_list_6</Trans></li>
                    <li><Trans>sl_pivot_headline_list_7</Trans></li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto={'/products/smart-locks/pivot/'} label={t('see_specs')} cls={'btn btn--orange'}/>
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/smart-locks/pivot-system.png"
                      loading={'lazy'}
                      width={324}
                      height={437}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Versa smart lock"
                  />
                </div>
              </div>
            </div>
          </section>


          <div id={'smartDigilink'} className="section-delimeter container" />
          <section className="smart-digilink">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/smart-locks/smart-digilink.png"
                      loading={'lazy'}
                      width={624}
                      height={461}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Digilink"
                  />
                  <div className="video-holder">
                    <ReactVideo
                        vURL={'https://cdn.mediavalet.com/usva/digilock/JzTSZmYf4kK5UVJLZzCi9w/rYz-WTrTgUyaUDk1YC511A/Widescreen720p/Digilock%20Digilink%20App%20Highlight%20V6.mp4'}
                        poster={posterDigilink} />
                  </div>
                </div>
                <div className="grid-two-col-item-single digilink">
                  <img className={'item-single-logo'} src={digilinkLogo} width={'225'} height={'88'} alt="Digilink logo"/>
                  <h2>
                    <Trans>sl_digilink_headline</Trans>
                  </h2>
                  <p><Trans>sl_digilink_headline_copy</Trans></p>
                  <ul>
                    <li><Trans>sl_digilink_list1</Trans></li>
                    <li><Trans>sl_digilink_list2</Trans></li>
                    <li><Trans>sl_digilink_list3</Trans></li>
                    <li><Trans>sl_digilink_list4</Trans></li>
                    <li>
                      <CustomLocalizedLink goto={'/blog/post/digilock-soc2-iso-certifications/'} label={t('networked_management_list_four')} />
                      <br/>
                      <Link to={'/blog/post/digilock-soc2-iso-certifications/'}>
                        <img src={sensibaLogo}
                             style={{marginLeft:'-7px', marginTop:'5px'}}
                             alt={'Sensiba Certified'}
                             width={'71'}
                             height={'85'}/>  
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <Modal show={showModal} handleClose={handleShowModal} cls={'smart-modal'} background={'light'}>
            <div className="modal-content">
              <p dangerouslySetInnerHTML={{__html:t('sl_modal_shared')}} />
              <p dangerouslySetInnerHTML={{__html:t('sl_modal_assigned')}} />
              <p dangerouslySetInnerHTML={{__html:t('sl_modal_reservation_mode')}} />
              <p dangerouslySetInnerHTML={{__html:t('sl_modal_parcel_pickup_mode')}} />
            </div>
          </Modal>
          <Modal show={showModalSOC2} handleClose={handleShowModalSOC2} cls={'soc2'} background={'light'}>
            <div className="modal-content">
              <h2>
                Digilink SOC 2 Compliance - Secured Network Smart Lock Management Solution
              </h2>
              <p>
                Business professionals around the world are continuously learning about the importance of information security, cybersecurity, and the need to protect the physical, digital, and information assets used to successfully conduct business. A commonly known way for individuals and organizations to understand whether they can trust an online service includes confirming that the online service provider has received a Systems and Organizations Control (SOC) report.
              </p>
              <p>
                Digilock has received a SOC 2 Type II report for its Digilink Smart Lock Management System, demonstrating the effective implementation of security practices to provide a secure web application product and protect customer data.
              </p>
              <h3>
                What Is A SOC 2 Report?
              </h3>
              <p>
                There are many organizations in the world that have developed standards for the appropriate safeguarding and protection of information. One of the most commonly known security standards used in the world today includes SOC reporting standards, issued by the American Institute of Certified Public Accountants (AICPA).
              </p>
              <p>
                A SOC report communicates state of operations for a service or product that is provided by an organization, referred to in SOC compliance as a “Service Organization”, and includes a wealth of information describing the covered service or product and its related operations and/or security practices.
              </p>
              <p>
                There are several types of SOC reports that can be issued for a Service Organization, including SOC 1, SOC 2, and SOC 3 reports, among others. Management of Service Organization, like Digilock, make the decisions on the type of SOC report that is required for their organization and the users of their system and service offerings.
              </p>
              <p>
                A SOC 2 report has a primary focus on the security related to a product or service and provides users of the product or service with a comprehensive understanding of security practices implemented by the Service Organization to protect the data and information technology resources that operate the product or service.
              </p>
              <h3>
                Understanding Type I vs Type II
              </h3>
              <p>
                SOC reports are issued with associated “types”, which communicate the extent to which a user of the SOC report can rely on the report’s information:
              </p>
              <ul>
                <li>
                  <strong>Type I</strong> - A Type 1 report provides an auditor’s opinion on the “design” of the security practices implemented for a product or service at one point in time (as of a specified date), but provides no opinion on the operation of these security practices over a period of time. Although Type 1 report is a good step to demonstrating appropriate security, it does not provide as much assurance as a Type 2 report.
                </li>
                <li>
                  <strong>Type II</strong> - A Type 2 report provides an auditor’s opinion on the “design and operation” of the security practices implemented for a product or service over a period of time. A SOC 2 report provides the most assurance about the security practices of a Service Organization, and results from the completion of a thorough audit (examination) of the Service Organization’s security practices for the product or service to validate that security practices were performed for a period of time (typically 6 months or a year).
                </li>
              </ul>
              <h3>
                A Smart Lock Management Solution You Can Trust
              </h3>
              <p>
                With our Digilink Smart Lock Management System, we provide our customers with a smart lock management solution that contributes to the physical security component of an improved network security and cybersecurity posture. Using Digilink, our customers can manage the locking and unlocking security for storage locations within their organization. This contributes to securing confidential or sensitive information and assets that need to be protected from unauthorized access. Digilink collects and stores data that is needed to enable system use. Our customers provide their data and expect that we will protect it as it lives within our environment.
              </p>
              <p>
                The receipt of SOC 2 Type II certification demonstrates that we protect customer information during the information processing lifecycle of our Digilink web application, and our customers can trust that their data is reasonably protected from security threats that may lead to data breaches or security compromises that would inappropriately disclose or divulge their information.
              </p>
              <h3>
                Request A Copy Of Our Digilink SOC 2 Report
              </h3>
              <p>
                Our SOC 2 report is available to Digilink customers upon request. Please contact our customer service team or your account manager to request a copy of our SOC 2 Type II report.
              </p>
            </div>
          </Modal>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
